/* The alert message box */
.Web3 {
  .alert {
    align-items: center;
    background-color: red;
    border-radius: 0px;
    box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.24);
    color: white;
    display: flex;
    height: 40px;
    justify-content: space-between;
    padding: 20px;
    margin-bottom: 1px;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 999999;
  }

  .alert .message {
    align-items: center;
    display: flex;
    width: 100%;
  }

  .alert .message .content{
    align-items:center;
    display: flex;
    flex-direction: row;
    font-size: 0.7rem;
    justify-content: center;
    word-break: break-all;
  }

  .alert .message::before {
    content: url('/images/info.svg');
    margin-right: 10px;
  }

  .alert a {
    color: white;
    text-decoration: underline;
  }

  .alert.error {
    background-color: #C70018;
  }

  .alert.error .message::before {
    content: url('/images/close.svg');
    height: 30px;
  }

  .alert.success {
    background-color: #00A652;
  }

  .alert.success .message::before {
    content: url('/images/success.svg');
    height: 30px;
  }

  .alert.info {
    background-color: #008EFF;
  }

  .alert.info .message::before {
    content: url('/images/info.svg');
    height: 30px;
  }

  .alert.warning {
    background-color: #F2A700
  }

  .alert.warning .message::before {
    content: url('/images/warning.svg');
    height: 28px;
  }

  /* The close button */

  .closebtn {
    color: white;
    cursor: pointer;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    margin-left: 15px;
    margin-right: 15px;
    transition: 0.3s;
  }


  /* When moving the mouse over the close button */

  .closebtn:hover {
    color: black;
  }

  .row.nonefound {
    margin-right: 0px;
  }

  .row.nonefound img {
    max-width: 250px;
  }

  .row.nonefound p {
    margin-bottom: 0px;
  }

  .bottom_notification {
    padding: 10px 30px;
    background: #3e00ff;
    color: white;
    width: 100%;
    margin-left: 0px;
  }

  .metamask_recc {
    width: 45%;
    display: inline-block;
  }

  .navbar-network {
    border: 1px solid #828282;
    color: #888;
    border-radius: 25px;
    font-size: 0.65rem;
    text-align: center;
  }

  .navbar-network i {
    font-size: 7px;
    position: relative;
    top: -1px;
  }

  .navbar-network a {
    text-decoration: underline;
  }

  #metamask_label img {
    position: relative;
    top: -2px;
  }

  #metamask_context .red_warning {
    background-color: #fbe0d6;
    color: #fb9470;
    padding: 5px 10px;
    border-radius: 2px;
    margin-bottom: 15px;
    display: block;
  }

  #metamask_context .red_warning a {
    color: #fb9470 !important;
    font-weight: bold;
    text-decoration: underline;
  }

  #metamask_context ul {
    font-size: 0.85rem;
    margin-top: 0.5rem;
    padding-left: 1rem;
  }

  #metamask_context li a {
    color: #0D0764;
  }

  #metamask_context .warning {
    text-align: center;
    background-color: #ff9800;
    border-radius: 5px;
  }

  #metamask_context .warning a {
    color: black;
    font-weight: bold;
  }

  .green {
    color: #26cd7f;
  }

  .red {
    color: #ff3232;
  }

  .alert_msg {
    top: 0px;
    width: 100%;
    z-index: 99999;
    background-color: #f44336;
    color: white;
    position: fixed;
    text-align: center; }

  .alert_msg.info {
    background-color: #2196F3;
  }
}
