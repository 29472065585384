.Public {
  .top-h-size {
          display: none;
      }

  .top-v-size {
          display: block;
      }

  .presale-timer-mobile {
      display: none;
  }

  .presale-timer-desktop {
      display: block;
  }

  .field-group {
      padding: 8px;
  }

  .input wrapper {

  }

  .nav-item {
          position: relative;
  }

      .nav-item {
          font-size: 1rem;
          margin-left: .3em;
          padding: 6px;
          height: 30px;
          width: 120px;
          margin:5px;
      }


      .nav-item span {
          font-family: 'Londrina Solid', sans-serif;
      }

      @media (max-width: 992px) {
          .navbar-nav {
              margin: 1rem 0rem;
          }
          .navbar-collapse.show .nav-item {
          width: 33%;
          margin: 5px auto;
          padding: 10px;
      }
  }

  @media all and (orientation:landscape) {
      .metamask.enjin1 {
          display: none;
      }

      .metamask.enjin2 {
          display: none;
      }

      .metamask.enjin3 {
          display: none;
      }

      .presale-timer-text {
          color: #121356;
          font-size: 1.5rem!important;
          margin: 0px 10px;
      }

      .presale-timer-clock {
          margin-bottom: 0px;
      }

      .presale-timer-clock span{
          font-size: 2.5rem;
          font-family: 'Londrina Solid', sans-serif;
      }

      .presale-header-cars {
          padding-bottom: 50px;
      }

      .blur-modal {
          align-items: center;
      }
  }

  @media all and (orientation:portrait) {
      .section-header {
          height: 100vh;
          h1 {
            padding-top: 20px;
          }
      }
      .presale-timer-mobile {
          display: flex;
      }

      .presale-timer-desktop {
          display: none;
      }

      .metamask.mm1 {
          display: none;
      }

      .metamask.mm2 {
          display: none;
      }

      .metamask.mm3 {
          display: none;
      }

      .footer-height-f {
      height: inherit;
      }

      .blur-modal {
          align-items: flex-start;
      }
  }

  @media (min-width: 576px) {

      section p {
          color: dimgray;
          font-size: 1.2rem;
      }
      
      .h-diamond {
          background: #121256;
          padding: 10px;
          height: 60px;
          max-width: 70%;
          position: relative;
          margin-bottom: 20px;
          text-align: center;
          margin: 0px auto;
      }

      .h-diamond h2 {
          z-index: 1;
          margin: auto;
          color: white;
          font-size: 2.1rem;
      }

      .h-diamond:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          border-bottom: 30px solid #121256;
          border-left: 30px solid white;
          border-right: 30px solid white;
      }

      .h-diamond:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          border-top: 30px solid #121256;
          border-left: 30px solid white;
          border-right: 30px solid white;
      }

      .custombg .h-diamond:before{
          border-bottom: 30px solid #5156ac;
          border-left: 30px solid #101046;
          border-right: 30px solid #101046;
      }

      .custombg .h-diamond:after{
          border-top: 30px solid #5156ac;
          border-left: 30px solid #101046;
          border-right: 30px solid #101046;
      }

      
      .usp-section p {
      font-size: 1.2rem;
      }

      h2 {
          font-size: 2.5rem;
      }

      h3 {
          font-size: 2.5rem;
      }

      .header-main-headline {
          max-width: 75%;
      }

      .header-main-headline h1 {
          font-size: 4rem;
      }

      .header-main-headline p {
          font-size: 1.5rem;
      }

      .socmed-icons {
          display: inline;
      }
  }

  @media (min-width: 768px) {



      .section-header-newsletter p {
          margin-bottom: 0.5rem;
      }

      .section-header-newsletter h4 {
          margin-bottom: 0.2rem;
      }

      .presale-headline {
      padding-top: 0%;
      font-size: 2rem;
      }

      .body > header > div.container.top-h-size > div > div:nth-child(1) > div.flex-shrink-1.section-header-newsletter.row > div.col-10.d-flex.flex-column.justify-content-center > h3 {
          font-size: 1.5rem!important;
      }

      .referral-text {
      font-size: 0.9rem!important;
  }

  }

  @media (min-width: 898px) {
      .top-h-size {
          display: block;
      }
      .top-v-size {
          display: none;
      }

      .section-header {
          height: 100vh;
          background-attachment: fixed;
      }

      .h-diamond {
          max-width: 40%;
      }

      .presale-headline {
      font-size: 3.5rem;
      }

      .presale-headline-text {
          font-size: 1rem;
      }

      .field-group {
      padding: 8px 10px;
  }

  }

  @media (min-width: 992px) {
    .Crate {
      max-width: 75%;
     }

    .Navigation {
      .nav-item {
        position: relative;
        background-color: #ff8824;
        border: 12px solid rgba(0,0,0,0.0001);
        border-image: url(../../images/ui/btn-nav.png) 12 fill round;
        background-clip: padding-box;
        font-size: 1rem;
        margin-left: .3em;
        padding: 6px;
        height: 30px;
        width: 140px;
        transform: translateY(0px);
        transition: 0.2s;
        &:hover {
        transform: translateY(5px);
        }
        span{
        padding-bottom: 10px;
        }
      }
      .nav-item.border-blue {
        border-image: url(../../images/bg/border-diagonal-blue-10px.png) 10 fill round;
      }
      .nav-item.cta {
        border-image: url(../../images/ui/btn-nav-cta.png) 12 fill round;
      }
    }

      .list-inventory-item {
      color: black;
      padding: 0px 10px 30px 10px;
      }

  }

  .showcase .list-inventory-item {
      max-width: 300px;
      margin: 0px auto;
  }

  @media (min-width: 1600px) {
      .presale-headline {
      font-size: 5rem;
      }
      .presale-headline-text {
          font-size: 1.6rem;
      }
  }

  @supports (-webkit-overflow-scrolling: touch) {
      .section-header {
          background-attachment: scroll;
      }
  }

  @media (max-width: 320px) {
      .email {
          width: 100px;
      }
      .col-3 .h-blue {
          font-size: 0.9rem!important;
      }
  }

  /* TEAM HEADLINE FIXES */

  @media (max-width: 1024px) {
      .team-headline-fix {
          font-size: 2.5rem!important;
          padding: 8px;
      }
  }

  @media (max-width: 414px) {
      .team-headline-fix {
          font-size: 1.6rem!important;
          padding: 5px;
      }
  }

  @media (max-width: 320px) {
      .team-headline-fix {
          font-size: 1.3rem!important;
          padding: 8px;
      }
  }

}
