
.Inventory {
  
  .list-inventory.stats {
    border-top: 2px lightgray solid;
  }

  .list-inventory-item:hover {
      color: black;
      text-decoration: none;
  }

  .card-title {
      text-decoration: none!important;

  }

  .card-body {
  }

  .card-img-top {
      border: 1px solid lightgray;
  }

  .indicator-rarity {
      width: 20px;
      position:absolute;
      right: 10px;
      top: 10px;
  }

  .inventory-parts-end-notif {
    padding: 30px;
    margin: 0 auto 20px auto;
    max-width: 80%;
    text-align: center;
    border: 1px solid lightgray;

    p {
      color: #212529;
    }
  }
  
  .page-link {
    background-color: #F6F6FF;
    border: 0px;
    color: gray;
  }

  .page-item:first-child .page-link {
    border-radius: 0px;
  }

  .page-item:last-child .page-link {
    border-radius: 0px;
  }

  .parts-command {
    margin: 0px 10px;
    padding: 6px;
    text-align: center; 
    width: 110px;
      button {
        width: 100%;
      }
      p {
        margin: 6px 0px;
        font-size: 10px;
      }
  }
}
