@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

.Public {
  * {
      font-family: 'Open Sans', sans-serif;
  }

  .fab {
    font-family: 'Font Awesome 5 Brands';
  }

  .fas {
    font-family: 'Font Awesome 5 Free';
  }


  p {
  margin-bottom: 0px;
  }

  $aos-distance: 1000px;
  @import 'node_modules/aos/src/sass/aos.scss';

  .bg-br {
      background-color: #121256;
  }

  .bg-transparent {
      background-color: rgba(0,0,0,0);
  }

  h1, h2, h3, h4, h5, h6 {
      font-family: 'Londrina Solid', sans-serif !important;
  }

  .btn-cta-mid {
      padding: 0px 0px;
      background: #FF8824;
      border: 16px solid rgba(0,0,0,0.0001);
      border-image: url(../../images/ui/btn-cta-md.png) 18 fill round;
      background-clip: padding-box;
      max-width: fit-content;
      margin-left: auto;
      margin-right: auto;
      h3 {
      color: #fbf967;
      font-size: 1.8rem;
      margin-top: -4px;
      margin-bottom: 0px;
      }
      h4 {
      color: white;
      font-size: 1.2rem;
      margin-bottom: 0px;
      }
    }
  .h-blue {
      color: #292994;
  }

  hr.h-border {
      width: 20%;
      margin-top: 0rem;
      margin-bottom: 1rem;
      border-top: 5px solid #f27917;
      line-height: 0;
  }

  hr.h-border.left {
      margin-left: 1px;
  }

  .h-diamond {
      background: #121256;
      padding: 10px;
      height: 60px;
      width: 100%;
      position: relative;
      margin-bottom: 20px;
      text-align: center;
       h2 {
            z-index: 1;
            margin: auto;
            color: white;
        }
  }

  .h-diamond:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      border-bottom: 30px solid #121256;
      border-left: 30px solid white;
      border-right: 30px solid white;
  }

  .h-diamond:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      border-top: 30px solid #121256;
      border-left: 30px solid white;
      border-right: 30px solid white;
  }

  .custombg .h-diamond:before{
      border-bottom: 30px solid #5156ac;
      border-left: 30px solid #101046;
      border-right: 30px solid #101046;
  }

  .custombg .h-diamond:after{
      border-top: 30px solid #5156ac;
      border-left: 30px solid #101046;
      border-right: 30px solid #101046;
  }

  section p {
      color: dimgray;
      font-size: 0.9rem;
  }

  .navbar {
      box-shadow: 0px 10px 10px rgba(0,0,0,0.3);
      border-bottom: 2px solid #37377f;
  }

  .navbar-brand {
      height: 50px;
  }

  .navbar-brand img {
      height: 100%;
  }

  .nav-link {
      display: inline-block;
      padding-bottom: 10px;
  }

  .nav-item {
      text-align: center;
      margin-left: 1.2em;
      white-space: nowrap;
      text-align: center;
  }

  .nav-item .fas,.fab {
      font-size: 2.5em;
  }

  .nav-img {
      height: 30px;
  }

  /* TOOLTIP */ 

  .tip-content {
    background-color: lightgray;
    color: gray;
    font-size: 0.7em;
    font-weight: 100;
    line-height: 1.2rem;
    padding: 10px;
    position: absolute;
    width: 200px;
    visibility: hidden;
    z-index: 1;
  }                   
  .tip:hover .tip-content {
    visibility: visible;
  }

  .header-main-headline {
      /* max-height: 80%; */
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
  }

  div.container-fluid.section-presale-timer {
      background-color: orange;
      top: 68px;
      position: fixed;
      z-index: 9;
      height: 60px;
      padding: 10px;
      line-height: 1rem;
      align-items: center;
      justify-content: center;
  }

  .presale-timer-desktop {
      padding: 0px 20px;
      background: #FF8824;
      min-width: 280px;
      border: 16px solid rgba(0,0,0,0.0001);
      border-image: url(../../images/bg/border-diagonal-orange.png) 16 fill round;
      background-clip: padding-box;
      box-shadow: 0px 35px 10px -30px rgba(0,0,0,0.5);
  }

  .presale-timer-text {
          margin-right: 10px;
          font-size: 0.9rem;
      }

      .presale-timer-clock {
      text-shadow: 0px 5px 3px rgba(0,0,0,0.3);
      }

  div.container-fluid.section-presale-timer span {
       font-family: 'Londrina Solid', sans-serif!important;
      color: white;
  }

  .header-main-headline p {
      font-size: .9rem;
  }

  .section-header {
      background-size: cover;
      background-attachment: fixed;
      background-position: center;
      background-repeat: no-repeat;
      padding-top: 68px;
      h1 {
        font-size: 4rem;
        text-shadow: 0px 7px 1px rgba(0,0,0,0.5);
      }
      .container {
      height: 100%;
      position: relative;
      }
  }

  .discord-link {
    p {
      font-size: 0.9rem;
      line-height: 1.3rem;
    }
  }
  .Home {
    .title {
      background: rgba(0,0,0,0.6);
      margin-bottom: 1.2rem;
      padding: 3rem 1rem;
      h1 {
        color: white;
        margin-bottom: -6px;
        margin-top: -20px;
        text-shadow: darkgray;
      }
      h2 {
      font-size: 3rem;
        margin-bottom: 10px;
      }
      p {
      color: #e6e5ec;
      font-size: 1rem;
      text-shadow: 0px 1px 1px rgba(0,0,0,0.5);
      }
    }
  }

  .section-header.main {
      background: url(../../images/pt/home/bg-pt.jpg) center no-repeat;
      background-size: cover;
  }

  .header-layout div {
      height: 100%;
  }

  .londrina-fix {
      font-family: 'Londrina Solid', sans-serif;
  }

  .img-layout div {
      padding: 0px 10px 20px 10px;
  }

  .img-layout img {
      width: 100%;
  }

  .custombg {
      /* background-image: url(../images/bg/race-ptn-2.gif); */
      background-color: #101046;
  }



  .section.card-array {

  }

  .usp-section {
      min-height: 200px;
  }

  .usp-section img {
      width: 100%;
  }


  .section-wip {
  padding-bottom: 300px;
  background-image: url(../../images/bg/bg-dark-b.png);
  }
  .section-wip img {
      width: 100%;
  }

  .logo-partners {
    margin: 20px;
    max-height: 50px;
    max-width: 200px;

  }

  /* FOOTER */

  footer {
      background: #101046;
      u {
        color: white;
      }
  }

  footer .container {
      padding: 20px 0px;
  }

  .container-socmed {
      display: inline-block;

      margin-bottom: 1em;
  }

  .socmed {
      display: inline;
      
  }

  div.socmed-icons {
  }

  .socmed-icons {
      display: block;
  }

  .socmed-icons-item {
      display: inline;
      margin: 0px 5px 0px 5px;
  }

  .socmed-icons-item i {
      color: white;
      font-size: 1.2rem;
  }

  .footer-links {
      color: white;
      font-size: 10px;
  }

  .footer-links a {
      color: white;
  }

  /* EX HELPERS */

  .header-ex.mr-10px {
      font-family: 'Londrina Solid', sans-serif;
      font-size: 1.2rem!important;
  }

  .box-shadow-lg {
      box-shadow: 0px 70px 40px -50px rgba(0,0,0,0.2);
  }

  .mr-5px {
      margin-right: 5px;
  }
  .mr-10px {
      margin-right: 10px;
  }

  .mb-5em {
      margin-bottom: 5em;
  }

  .p--5 {
      transform: translateY(-10px);
  }

  .rem-pt7 {
      font-size: 0.7rem;
  }

  .w-300px {
      max-width: 300px;
  }
  .active {
      text-shadow: 0px 3px 2px rgba(0,0,0,0.2);
  }


  .text-yellow {
      color: yellow!important;
  }

  .text-gray {
      color: darkgray!important;
  }

  .ekko-lightbox-nav-overlay a:hover {
      color: white;
  }

  .separator {
    position: relative;
    width: 100%;
    height: 32px;
  }

  .separator-top {
      transform: translateY(31px);
      width: 100%;
  }

  .separator.bottom {
      transform: translateY(-31px);
  }
  .separator::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba (0,0,0,0);
    clip-path: polygon(100% 0, 0 0, 0 100%);
  }

  .separator::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: white;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
  }

  .separator.reverse {
      transform: rotate(180deg);
  }

  /* BACK TO TOP BTN */

  button.back-to-top{
    margin: 0 !important;
    padding: 0 !important;
    background: gray;
  	height: 0px;
    width: 0px;
    overflow: hidden;
  	border-radius: 50px;
  	-webkit-border-radius: 50px;
  	-moz-border-radius: 50px;
    color: transparent;
  	clear: both;
    visibility: hidden;
    position: fixed;
    cursor: pointer;
    display: block;
    border: none;
    right: 50px;
      bottom: 50px;
    font-size: 0px;
    outline: 0 !important;
    z-index: 99;
    transition: all .3s ease-in-out;
  }

  button.back-to-top:hover,
  button.back-to-top:active,
  button.back-to-top:focus,{
    outline: 0 !important;
  }

  button.back-to-top::before {
    content: "\f077";
    font-family: "FontAwesome";
    display: block;
    vertical-align: middle;
    margin: -5px 0 auto;
  }

  button.back-to-top.show {
      display: block;
      background: #fff;
      color: gray;
      font-size: 25px;
      right: 25px;
      bottom: 25px;
      height: 50px;
      width: 50px;
      visibility: visible;
  	box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.25);
      -webkit-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.25);
      -moz-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.25);
  }
  button.back-to-top.show:active {
      box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.25);
      -webkit-box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.25);
      -moz-box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.25);
  }

  /* Slide */

  .come-in {
    transform: translateY(150px);
    animation: come-in 0.8s ease forwards;
  }

  .come-in:nth-child(odd) {
    animation-duration: 0.6s; /* So they look staggered */
  }

  @keyframes come-in {
    to { transform: translateY(0); }
  }

  /* EFFECTS */

  .pulse {
    box-shadow: 0 0 0 rgba(204,169,44, 0.4);
    animation: pulse 2s infinite;
  }

  .button-common {
      padding: 0px 12px;
      background: #FF8824;
      border: 16px solid rgba(0,0,0,0.001);
      -webkit-border-image: url(../../images/bg/border-diagonal-orange.png) 16 fill round;
      -moz-border-image: url(../../images/bg/border-diagonal-orange.png) 16 fill round;
      -o-border-image: url(../../images/bg/border-diagonal-orange.png) 16 fill round;
      border-image: url(../../images/bg/border-diagonal-orange.png) 16 fill round;
      background-clip: padding-box;
      color: white;
      font-family: 'Londrina Solid', sans-serif;
      font-size: 1.5rem;
      text-align: center;
      transition: 0.2s;
  }

  .button-common:disabled {
    background: #646362;
    -webkit-border-image: none !important;
    -moz-border-image: none !important;
    -o-border-image: none !important;
    border-image: none !important;
  }

  .button-common:hover {
      transform: translateY(-5px);
  }
  .button-common.button-small {
      border: 10px solid rgba(0,0,0,0.001);
      -webkit-border-image: url(../../images/bg/border-diagonal-orange-10px.png) 10 fill round;
      -moz-border-image: url(../../images/bg/border-diagonal-orange-10px.png) 10 fill round;
      -o-border-image: url(../../images/bg/border-diagonal-orange-10px.png) 10 fill round;
      border-image: url(../../images/bg/border-diagonal-orange-10px.png) 10 fill round;
  }

  .button-common.button-small:disabled {
      border: 10px solid rgba(0,0,0,0.001);
      -webkit-border-image: url(../../images/bg/border-diagonal-gray-10px.png) 10 fill round;
      -moz-border-image: url(../../images/bg/border-diagonal-gray-10px.png) 10 fill round;
      -o-border-image: url(../../images/bg/border-diagonal-gray-10px.png) 10 fill round;
      border-image: url(../../images/bg/border-diagonal-gray-10px.png) 10 fill round;
      transform: translateY(0px);
  }

  .button-common.button-smaller {
      border: 7px solid rgba(0,0,0,0.001);
      -webkit-border-image: url(../../images/bg/border-diagonal-orange-7px.png) 7 fill round;
      -moz-border-image: url(../../images/bg/border-diagonal-orange-7px.png) 7 fill round;
      -o-border-image: url(../../images/bg/border-diagonal-orange-7px.png) 7 fill round;
      border-image: url(../../images/bg/border-diagonal-orange-7px.png) 7 fill round;
      font-size: 0.9rem;
      padding: 0px 7px;
  }

  .button-common.button-smaller.inactive {
      border: 7px solid rgba(0,0,0,0.001);
      -webkit-border-image: url(../../images/bg/border-diagonal-lightgray-7px.png) 7 fill round;
      -moz-border-image: url(../../images/bg/border-diagonal-lightgray-7px.png) 7 fill round;
      -o-border-image: url(../../images/bg/border-diagonal-lightgray-7px.png) 7 fill round;
      border-image: url(../../images/bg/border-diagonal-lightgray-7px.png) 7 fill round;
      font-size: 0.8rem;
      padding: 0px 7px;
  }

  .button-common.transaction a {
    font-family: 'Londrina Solid', sans-serif;
    font-size: 0.8em;
    color: white;
  }

  .button-common.transaction a:hover {
    text-decoration: none;
  }

  .small-border {
      border: 10px solid rgba(0,0,0,0.0001);
      -webkit-border-image: url(../../images/bg/border-diagonal-orange-10px.png) 10 fill round;
       -moz-border-image: url(../images/bg/border-diagonal-orange-10px.png) 10 fill round;
      -o-border-image: url(../../images/bg/border-diagonal-orange-10px.png) 10 fill round;
      border-image: url(../../images/bg/border-diagonal-orange-10px.png) 10 fill round;
  }

  .mt-6 {
      margin-top: 6rem!important;
  }

  .mt-70px {
      margin-top: 68px;
  }

  .blur-modal {
    position: fixed;
    top: 0;
    background-color: rgba(0,0,0,0.8);
    height: 100vh;
    width: 100vw;
    z-index: 9999999;
    overflow-y: scroll;
  }

  .modal-container {
    background: white;
    min-width: 500px;
    padding: 30px;

  }

  .modal-container.vertical {
    max-width: 500px;
  }

  input.cratevalue {
    font-size:2rem!important;
  }

  .fsize-1p5 {
    font-size: 1.5rem!important;
  }

  .bg-transparent {
    background: rgba(0,0,0,0);
  }

  .p-5px {
    padding: 5px!important;
  }

  .font-30px {
    font-size: 30px;
  }

  .mt-m70px {
    margin-top: -70px;
  }

  .footer-height-f {
      min-height: calc(100vh - 496px);
  }

  .overflow-wrap {
    word-wrap: break-word;
  }

  .ptpb-100px {
    padding-bottom: 100px;
    padding-top: 100px;
  }

  .pt-100px {
  padding-top: 100px;
  }

  .pb-100px {
  padding-bottom: 100px;
  }

  a {
    text-decoration: none!important;
  }

  .btn.btn-success {
    background-color: #303955;
    padding: 0.5em 1.5em;
    border-radius: 0.5em;
  }

  .btn.btn-success:hover {
    background-color: #303955;
  }

  .Web3 {
    display: flex;
    flex-direction: column;
  }

  .content {
    flex: 1 0 auto;
  }
  .footer {
    flex-shrink: 0;
  }

  h4 {
    font-family: 'Londrina Solid', sans-serif;
  }

  .body-fix-position {
    position: fixed;
    overflow: hidden;
  }

  .text-danger-bg {
    background-color: red;
    padding: 10px;
    text-align: center;
    max-width: 80%;
  }

  .pending-tx {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 0.8rem;
    max-width: 100%;
    white-space: nowrap;
    align-items: center;
    justify-content: center;


    .hash:last-child {
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
  }

  .mw-60 {
  max-width: 60%;
  }

  .z-1 {
  z-index: 1;
  }

  .red {
  color: red;
  }

  .green {
  color: forestgreen;
  }

  .strikethrough {
    text-decoration: line-through;
  }

  .btn-sm-gray {
    border-image: url(../../images/bg/border-diagonal-gray-10px.png) 10 fill round!important;
  }

  .bb-2px-lightgray {
  border-bottom: 2px lightgray solid;
  }

  .modal-container.vertical h4 {
    padding: 6px;
    border-bottom: 1px lightgray solid;
  }

  .modal-container.vertical span {
    font-size: 0.5rem;
    font-weight: bold;
  }

  .modal-container.vertical .rarity-tag span {
    font-size: 1rem;
    font-weight: bold;
  }

  .modal-container.vertical p {
    font-size: 0.8rem;
  }

  .section-carousel {
    max-height: 280px;
    margin-bottom: 120px;
    h1 {
      font-size: 4rem;
      text-align: center;
      position: relative;
      top: -180px;
      color: white;
      text-shadow: 0px 7px 2px rgba(0,0,0,0.3);
      }
    .carousel {
      background-color: rgba(0,0,0,0);
      overflow: hidden;
      width: 100%;
      .carousel-wip {
        background-color: rgba(0,0,0,0);
        height: 277px;
        width: 2262px;
        img {
        margin-right: 0px;
        }
        :first-child {
          animation: carousel-left 30s linear infinite;
        }
      }
    }
  }

  @keyframes "carousel-left" {
    0% {
      margin-left: 0px;
    }
    100% {
      margin-left: -2382px;
    }
  }

  .parts-command {
    margin: 0px 10px;
    padding: 6px;
    text-align: center;
    width: 40%;
    margin: 0px 10px;
      button {
        width: 100%;
      }
      p {
        margin: 6px 0px;
        font-size: 12px;
      }
  }

  .login-prompt {
      min-height: 90vh;
  }

  .fontsize-normal {
    font-size: 1rem;
  }

  // NEW LAYOUT

  table {
    width: 100%;
    td {
      padding-top: 12px;
      span {
        display: block;
        font-size: 1.3rem;
        padding-bottom: 0.4rem;
      }
    }
    .year {
      color: darkblue;
      font-size: 2rem;
      padding-right: 5rem;
      padding-top: 0px;
      width: 40%;
      text-align: end;
      vertical-align: top;
      h3 {
        font-size: 3rem;
        text-shadow: 0px 4px 1px rgba(0,0,0,0.2);
      }
    } 
  }

  .presale-timer-mobile {
    background: orange;
    color: white;
    padding: 8px;
    position: fixed;
    top: 68px;
    z-index: 99;
    h1 {
      font-size: 1.6rem;
    }
    p {
      font-family: 'Londrina Solid', sans-serif;
      line-height: 1;
    }
  }
  .section-banner-large {
    background-position: center;
    box-shadow: 0px 30px 20px -20px rgba(0,0,0,0.5);
    margin-bottom: 120px;
    min-height: 600px;
    padding: 0px;
    h1 {
      font-size: 4rem;
      text-shadow: 0px 7px 1px rgba(0,0,0,0.2);
    }
    p {
      color: white;
    }

    .label-category {
      border-left: 5px red solid;
      max-width: 500px;
      padding: 0px 8px;
      span {
        font-family: 'Open Sans', sans-serif;
        font-size: 1.4rem;
      }
    }
    .notice-bottom {
    background: #2E2E58;
      color: white;
      font-size: 0.8rem;
      padding: 14px;
      a {
        color: rgba(255,255,255,0.8);
      }
    }
  }

  .section-banner-large.car-brands {
       background-image: url(../../images/s1/home/bg-s1-car-brands.jpg);
       background-size: cover;
       .img-layout {
       .row {
          div {
          margin-bottom: 20px;
            max-width: 280px;
            h4 {
              margin: 10px;
            }
          }
       }

      }
  }
  .section-banner-large.tournament {
    background-image: url(../../images/s1/home/bg-s1-feature-a.jpg);
    max-height: 664px;
  }

  .section-banner-large.duel-cup {
    background-image: url(../../images/s1/home/bg-s1-feature-b.jpg);
    max-height: 664px;
  }


  .section-banner-large.overlap-top {
      margin-top: -40px;
  }

  .card-array {
    margin-bottom: 120px;
    .col-md-12 {
      background: gray;
      box-shadow: 0px 20px 10px -10px rgba(0,0,0,0.2);
      min-height: 600px;
      padding: 1rem;
      h2 {
        color: white;
        font-size: 4rem;
        text-shadow: 0px 7px 1px rgba(0,0,0,0.2);
      }
      div {
        background: #2E2E58;
        padding: 1rem;
        margin-bottom: 10px;
        p {
          color: white;
          font-size: 1rem;
          margin: 0px;
        }
      }
    }
  }



  .card-array.main-feature {
    .d-flex {
    background-size: cover;
    }
    .build {
    background-image: url(../../images/s1/home/bg-s1-build.jpg);
    }
    .race {
    background-image: url(../../images/s1/home/bg-s1-race.jpg);
    }
    .earn {
    background-image: url(../../images/s1/home/bg-s1-earn.jpg);
    }
  }

  .card-array.s1 {
    .d-flex {
    background-size: cover;
    }
    .legendary {
    background-image: url(../../images/s1/sale1/bg-s1-legendary.jpg);
    }
    .chances {
    background-image: url(../../images/s1/sale1/bg-s1-chances.jpg);
    }
    .exclusive {
    background-image: url(../../images/s1/sale1/bg-s1-exclusive.jpg);
    }
  }
  .s1 {
    .card-array {
      h2 {
      font-size: 2rem;
      text-shadow: 0px 3px 1px rgba(0, 0, 0, 0.4);
      }
    }
  }
  .item-page {
    h3  {
      font-size: 2rem;
    }
    table {
      margin: 0px 0px 10px;
      th {
        background-color: #F6F6FF;
        border: 1px solid lightgray;
        padding: 6px 8px;
      }
      td {
        border: 1px solid lightgray;
        padding: 6px 8px;
        text-align: center;
      }
    }

  }


}
