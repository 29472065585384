.Public {
    .ck {
        h1 {
        font-size: 5rem;
        }
        .module {
            .image {
                padding: 20px;
                background: white;
                border-radius: 50px;
                box-shadow: 0px 20px 10px -5px rgba(0,0,0,0.2);
            }
            .image:before {
                content: '';
                position: absolute;
                width: 0;
                height: 0;
                bottom: 100%;
                left: 50%; // offset should move with padding of parent
                border: .75rem solid transparent;
                border-top: none;

                border-bottom-color: #fff;
                filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, .1));
            }
            .desc { 
                padding: 40px;
                background: white;
                border-radius: 20px;
                box-shadow: 0px 20px 10px -10px rgba(0,0,0,0.2);
            }
        }
        
    }
    .section-kitty-trick {
        background: #fff4ea;
        padding: 80px 0px;
        text-align: center;
        h2 {
            font-size: 4rem;
            color: #634fd4;
            text-shadow: 0px 3px 3px rgba(0,0,0,0.2);
        }
        p {
            color: rgba(0,0,0,0.6);
        }
        .panel-kitty-trick {    
            margin-bottom: 28px;
            div {
                background: white;
                padding: 30px 20px;
                box-shadow: 0px 10px 10px -5px rgba(0,0,0,0.1);
                .card-desc {
                overflow: hidden;
                height: 60px;
                position: absolute;
                }
                .card-desc:after {
                    content: "";
                    text-align: right;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    width: 70%;
                    height: 1.2em;
                    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
                }
            }
            h4 {
                color: rgba(0,0,0,0.7);
                font-size: 2rem;
            }
            p {
                color: rgba(0,0,0,0.5);
                font-size: 0.9rem;
                text-overflow: ellipsis;
            }
            button {
                font-size: 1rem;
            }
        }

    }
}