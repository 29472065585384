.Alpaca {
  margin-top: 20px;

  input {
    font-size: 1rem !important;
  }

  .nav-tabs {
    .nav-item {
      padding: 0;
      height: auto;
      width: auto;
      margin: 0;
      margin-bottom: -1px;
    }

    .nav-link {
      font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
      padding: .5rem 1rem !important;
      line-height: 1.5;
    }
  }
}
.nav-admin {
  text-align: center;
}
.nav-admin *{
  display: inline-block;
}
.nav-item-admin {
  margin-right: 10px;
}
.AlpacaWrapper {
  position: absolute;
  width: 100%;
}
.Alpaca-Login-Wrapper span{
    color: black;
}
.Alpaca-Login-Wrapper {
  width: 100%;
  height: 100%;
  background: white;
  position: absolute;
  z-index: 999;
  text-align: center;
  padding-top: 90px;  
}
.Alpaca-Users-Wrapper {
  text-align: center;
}
.Alpaca-Users-Wrapper *{
  display: block;
  margin: 10px auto;
}
.Alpaca-Users-Wrapper input{
  width: 460px;
}
.button-list *{
  display: inline;
  margin: 10px;
}