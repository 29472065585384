.Press {
  .section-header.header-main {
      background-image: url(../../images/presale/bg.jpg);
      background-size: cover;
      height: 100vh;
  }

  .about-item {
      padding: 0px 15px 20px 15px;
      margin-bottom: 20px;
  }

  .about-item img {
      max-width: 180px;
      border-radius: 50%;
      margin-bottom: 20px;
  }

  .about-item h5 {
      margin-bottom: 0px;
  }
  .section-execteam p {
      line-height: 1.5em;
      color: rgba(255,255,255,0.8);
  }

  .fa-linkedin {
      font-size: 2rem;
      margin-top: 10px;
      color: black;
  }

  .section-execteam .fa-linkedin {
      color: white;
  }

  .team-photo {
      width: 100%;
      background: url(../../images/about-us/team-photo.jpg) center no-repeat;
      background-color: gray;
      background-size: cover;
  }

  .p-50 {
      padding-left: 50px;
      padding-right: 50px;
  }
}
