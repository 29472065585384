.InventoryPart {
  .list-inventory-item  {
      color: black;
      padding: 10px 10px 10px 10px;
      border: 8px solid #AAA1AF;
      min-height: 340px;
      background-color: white;
      max-width: 300px;
      margin: 0 auto;
      border-radius: 0px !important;
      #partNumber {
        color: rgba(0,0,0,0.8);
      }
        .card-body {
          padding: 0rem 1.25rem 0.25rem 1.25rem !important;
        }
  }
  
  .status-claiming {
    color: white;
    height: 40px;
    margin-top: -40px;
    text-align: center;
    vertical-align: center;
    .frame {
        position: relative;
        span {
            line-height: 40px;
        }
        .styling {
            background-image: url(../../images/ui/style-panel-claim-white.png);
            height: 40px;
            margin-top: -40px;
            position: absolute;
            width: 30px;
        }
        .styling.left {
            left: 0;
        }
        .styling.right {
            right: 0;
            transform: rotate(180deg);
        }
        .tip-content-part-status {
          background-color: lightgray;
          color: gray;
          font-size: 0.8rem;
          font-weight: 100;
          line-height: 1.2rem;
          padding: 10px;
          position: absolute;
          width: 200px;
          visibility: hidden;
          z-index: 1;
        }       
    }
  }

  .tip-content-part-status {
    position: absolute;
    visibility: hidden;
    }       

  .status-claiming.claiming {
    background-color: #D91010;
    .frame:hover .tip-content-part-status-claiming {
      visibility: visible;
    }        
  }
  .status-claiming.queued {
    background-color: #C12FD1;
    .frame:hover .tip-content-part-status-queued {
      visibility: visible;
    }         
  }
  .status-claiming.claim {
    background-color: #48CB1D;        
  }


  .part-name {
      min-height: 80px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-top: 0.25rem;
  }

  .part-name h5 {
      vertical-align: middle;
  }

  a {
  	text-decoration: none!important;
  }

  .list-inventory-item.legendary {
      border: 8px solid #C7B600;
      background-color: #FAFB75;
      .styling {
        background-image: url(../../images/ui/style-panel-claim-gold.png);
      }
  }

  .list-inventory-item.epic {
      border: 8px solid #6BD7E5;
  }

  .list-inventory-item.rare {
      border: 8px solid #E68F29;
  }

  @media (min-width: 992px) {
  	.parts-tag-group {
  	}

  }

  .parts-tag {
      /* background: gray; */
      height: 16px;
      width: 60px;
      position: relative;
      text-align: center;
      margin: 0px 1px;
      text-transform: uppercase;
  }

  .parts-tag span {
  	font-family: 'Open Sans', sans-serif;
  	font-size: 0.5rem;
      z-index: 1;
      margin: auto;
      color: white;

  }

  .parts-tag:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      border-bottom: 8px solid gray;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
  }

  .parts-tag:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      border-top: 8px solid gray;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
  }

  .parts-tag.prime:before {
      border-bottom: 8px solid #591C8D;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
  }

  .parts-tag.prime:after {
      border-top: 8px solid #591C8D;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
  }

  .parts-tag.perfect:before {
      border-bottom: 8px solid #23C762;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
  }

  .parts-tag.perfect:after {
      border-top: 8px solid #23C762;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
  }

  .parts-tag.season1:before {
      border-bottom: 8px solid green;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
  }

  .parts-tag.season1:after {
      border-top: 8px solid green;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
  }

  .card-img-top {
  	border: none!important;
  }

  .rarity-tag {
      /* background: gray; */
      height: 30px;
      width: 100px;
      position: relative;
      text-align: center;
      margin: 0px 1px;
  }

  .rarity-tag span {
      font-family: 'Londrina Solid', sans-serif;
      font-size: 1rem;
      z-index: 1;
      margin: auto;
      color: white;

  }

  .rarity-tag:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      border-bottom: 15px solid gray;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
  }

  .rarity-tag:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      border-top: 15px solid gray;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
  }

  .rarity-tag.common:before {
      border-bottom: 15px solid #AAA1AF;
  }

  .rarity-tag.common:after {
      border-top: 15px solid #AAA1AF;
  }

  .rarity-tag.rare:before {
      border-bottom: 15px solid #E68F29;
  }

  .rarity-tag.rare:after {
      border-top: 15px solid #E68F29;
  }

  .rarity-tag.epic:before {
      border-bottom: 15px solid #6BD7E5;
  }

  .rarity-tag.epic:after {
      border-top: 15px solid #6BD7E5;
  }

  .rarity-tag.legendary:before {
      border-bottom: 15px solid #C7B600;
  }

  .rarity-tag.legendary:after {
      border-top: 15px solid #C7B600;
  }
}
