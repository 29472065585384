.CrateAction {
  .crate-open {
    	width: 100%;
    	height: 600px;
      background-size: contain;
      background-position: center;
  }

  // .crate-open.animate {
  //  animation: crate-open-play 1s steps(8);
  //    animation-fill-mode: forwards;
  // }

  
  .crate-open.axie {
    background: url('../../images/misc/axie-crate-anim.png') no-repeat;
  }

  .crate-open.s0wood {
    background: url('../../images/crates/crate-s0-wood.gif') no-repeat;
    background-size: contain;
      background-position: center;
  }

  .crate-open.s0bronze {
    background: url('../../images/crates/crate-s0-bronze.gif') no-repeat;
    background-size: contain;
      background-position: center;
  }

  .crate-open.s0silver {
    background: url('../../images/crates/crate-s0-silver.gif') no-repeat;
    background-size: contain;
      background-position: center;
  }

  .crate-open.s0gold {
    background: url('../../images/crates/crate-s0-gold.gif') no-repeat;
    background-size: contain;
      background-position: center;
  }

  .crate-open.s1wood {
    background: url('../../images/crates/crate-s1-wood.gif') no-repeat;
    background-size: contain;
      background-position: center;
  }

  .crate-open.s1bronze {
    background: url('../../images/crates/crate-s1-bronze.gif') no-repeat;
    background-size: contain;
      background-position: center;
  }

  .crate-open.s1silver {
    background: url('../../images/crates/crate-s1-silver.gif') no-repeat;
    background-size: contain;
      background-position: center;
  }

  .crate-open.s1gold {
    background: url('../../images/crates/crate-s1-gold.gif') no-repeat;
    background-size: contain;
      background-position: center;
  }

  .crate-open.s1ck {
    background: url('../../images/crates/crate-kitty.png') no-repeat;
    background-size: contain;
    background-position: center;
  }

  .crate-open.s1axie {
    background: url('../../images/misc/axie-crate-anim.png') no-repeat;
    background-size: contain;
      background-position: center;
  }

  .s0wood.animate {
    background: url('../../images/crates/crate-s0-wood-open.gif') no-repeat;
    background-size: contain;
      background-position: center;
  }

  .s0bronze.animate {
    background: url('../../images/crates/crate-s0-bronze-open.gif') no-repeat;
    background-size: contain;
      background-position: center;
  }

  .s0silver.animate {
    background: url('../../images/crates/crate-s0-silver-open.gif') no-repeat;
    background-size: contain;
      background-position: center;
  }

  .s0gold.animate {
    background: url('../../images/crates/crate-s0-gold-open.gif') no-repeat;
    background-size: contain;
      background-position: center;
  }

  .s1wood.animate {
    background: url('../../images/crates/crate-s1-wood-open.gif') no-repeat;
    background-size: contain;
      background-position: center;
  }

  .s1bronze.animate {
    background: url('../../images/crates/crate-s1-bronze-open.gif') no-repeat;
    background-size: contain;
      background-position: center;
  }

  .s1silver.animate {
    background: url('../../images/crates/crate-s1-silver-open.gif') no-repeat;
    background-size: contain;
      background-position: center;
  }

  .s1gold.animate {
    background: url('../../images/crates/crate-s1-gold-open.gif') no-repeat;
    background-size: contain;
      background-position: center;
  }

  .s1ck.animate {
    background: url('../../images/crates/crate-kitty-open.gif') no-repeat;
    background-size: contain;
    background-position: center;
  }

}
