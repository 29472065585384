.Home {
  .section-header {
      padding: 20px 0px 20px 0px;
  }

  .section-header .container {
      padding: 0px;
  }

  .discord-link {
      text-align: center;
  }

  .discord-link h3 {
      color: #ebef1b;
  }

  .discord-link span {
      color: white;
      font-size: 3rem;
  }

  .discord-button {
      font-size: 1rem;
  }

  .section-header-newsletter {
      color: white;
      z-index: 9;
      display: flex;
      flex-direction: row;
      justify-content: center;
      max-width: 500px;
      .field {
      background-color: #37377f;
      padding: 14px 18px;
        .title {
        color: #F4FB2F;
        font-size: 1.2rem;
        }
        p {
        font-size: 0.8rem;
        }
      }
      .col-3,.col-2 {
      background: #6B6DEC;
      padding: 20px;
      }
      h4 {
      font-size: 1rem;
      color: #ebef1b;
      }
  }

  .top-v-size .section-header-newsletter {
      margin: 0px auto;
  }
\
  .section-presale-intro .col-sm-12 img {
      max-width: 300px;
      margin: 0px;
  }

  .section-presale-intro a:hover {
      text-decoration: none;
      color: white;
  }

  .section-usp.container-fluid {
  }

  .section-usp.container-fluid .container {
  }


  .section-partners {
      background: url("../../images/bg/bg-gray.jpg")bottom no-repeat;
      background-size:100% 75%;
      padding-bottom: 120px;
      .container {
        background: white;
        box-shadow: 0px 30px 20px -20px rgba(0,0,0,0.5);
        padding: 40px 0px;
      }
      h1 {
        color: rgba(0,0,0,0.8);
        font-size: 4rem;
        margin: 20px;
        text-shadow: 0px 5px rgba(0,0,0,0.3);
      }
      .logos {
      }
  }

  .col-3 .h-blue {
      color: white;
      margin-bottom: 0px!important;
  }

  .col-2 .h-blue {
      color: white;
      margin-bottom: 0px!important;
  }

  .section-header-newsletter p {
      font-size: .6rem;
  }

  .email {
      width: 120px;
  }

  input {
      font-size: .8rem;
  }

  .form-row {
      margin-left: 0px!important;
  }
  .btn-primary {
      border-color: rgba(0,0,0,0) !important;
      background-color: rgba(0,0,0,0) !important;
  }

  .sub-button {
          height: 100%;
          position: relative;
          border-radius: 0px;
          background-color: #f27917 !important;
          text-align: center;
      }

      .sub-button:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        border-bottom: 9px solid #f27917;
        border-left: 9px solid #37377f;
        border-right: 9px solid #37377f;
      }

      .sub-button:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        border-top: 9px solid #f27917;
        border-left: 9px solid #37377f;
        border-right: 9px solid #37377f;
      }

  .main-logo {
      max-width: 400px;
      img {
        padding: 10px;
      }
  }

  /* main button styling */

  .main-btn {
          font-family: 'Londrina Solid', sans-serif;
          color: white;
          position: relative;
          background: #f27917;
          font-size: 1.5rem;
          margin-left: .3em;
          height: 80px;
          width: 200px;
          padding: 16px 30px;
          border: 0px;
      }
       .main-btn:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        border-bottom: 12px solid #f27917;
        border-left: 12px solid #fff;
        border-right: 12px solid #fff;
      }

      .main-btn:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        border-top: 12px solid #f27917;
        border-left: 12px solid #fff;
        border-right: 12px solid #fff;
      }

  .gray-border {
      margin: 100px 0px 0px 0px;
      border: 5px solid lightgray;
  }

  .alto-fix {
      filter: invert(100%);
  }

  .section-roadmap .col-md-2 {
      padding: 10px;
  }

  .section-press .container {
      max-width: 800px;
  }

  @media (min-width: 576px) {
      .email {
          width: 150px;
      }

      .section-header .container {
      padding: 20px 15px 20px 15px;
      }
  }

  @media (max-width: 576px) {
      .main-logo {
          max-width: 100%;
          padding: 10px;
      }

  }
}
