.KyberPopup {
  .modalDismiss {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 24px;
    right: -15px;
    top: -15px;
    font-size: 36px;
    background: #ffffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
  }
}
