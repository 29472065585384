/* DivTable.com */
.AlpacaTokenStatus {
	width: 960px;
}

.Alpaca-Login-Wrapper * {
    display: block;
    margin: 10px auto;
}
.Alpaca-Login-Wrapper span {
 	color: white
}
.Alpaca-Login-Wrapper input {
 	width: 500px;
}
.divTable{
	display: table;
	width: 100%;
	text-align: center;
}
.divTableRow {
	display: table-row;
}
.divTableHeading {
	background-color: #EEE;
	display: table-header-group;
}
.divTableCell, .divTableHead {
	border: 1px solid #999999;
	display: table-cell;
	padding: 3px 10px;
}
.divTableHeading {
	background-color: #EEE;
	display: table-header-group;
	font-weight: bold;
}
.divTableFoot {
	background-color: #EEE;
	display: table-footer-group;
	font-weight: bold;
}
.divTableBody {
	display: table-row-group;
}
.defaultBtn {
	margin-top: 10px;
	margin-right: 10px;
	margin-bottom: 10px;
}
#options-cont {
  width: 100%;
  margin-bottom: 20px;
}
#options-cont * {
  margin-right: 10px;
}
.options-cont-child-1 {
  width: 30%;
  display: inline;
}
.options-cont-child-2 {
  width: 30%;
  display: inline;
}

.options-cont-child-3 {
  width: 30%;
  display: inline;
}
.options-cont-child-3 input {
  width: 100px;
}
.options-cont-child-4 {
  display: inline;
  font-size: 21px;
    font-weight: 600;
        font-weight: 600;
    float: right;
}
.options-cont-child-4 input {
  width: 13px;
  height: 13px;
  padding: 0;
  margin:0;
  vertical-align: bottom;
  position: relative;
  top:-4px;
  *overflow: hidden;
}
.options-cont-child-4 input[type="checkbox"]{
  width: 20px;
    height: 20px;
}

.divTb {
  height: 350px;
  overflow-y: scroll;
  border-bottom: 1px solid;
  margin-top: -1px;
}
.empty {
	margin-top: 20px
}
.label {
	padding-right: 19px;
}

.divTableCell:nth-child(1) {
	width: 5%
}
.divTableCell:nth-child(2) {
	width: 10%
}
.divTableCell:nth-child(3) {
	width: 40%
}
.divTableCell:nth-child(4) {
	width: 10%
}
.divTableCell:nth-child(5) {
	width: 10%
}

/*
.divTable.label {
	width: 98% !important;
}


.divTable.label .divTableCell:nth-child(1) {
	width: 5%
}
.divTable.label .divTableCell:nth-child(2) {
	width: 9.99%
}
.divTable.label .divTableCell:nth-child(3) {
	width: 39.99%
}
.divTable.label .divTableCell:nth-child(4) {
	width: 10.7%;
}
.divTable.label .divTableCell:nth-child(5) {
	width: 10%;
}

*/
.tooltiplink {
  position: relative;
  display: inline-block;
}

.tooltiplink .tooltiptext {
  visibility: hidden;
  width: 160px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -80px;
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events:none;
}

.tooltiplink .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltiplink:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}