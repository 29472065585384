.AccountNav {
  .user-id {
      text-align: center;
      width: calc(100% - 30px);
      word-break: break-all;
      overflow-wrap: break-word;
      .table-content {
        border-bottom: 3px lightgray solid;
        border-left: 1px lightgray solid;
        border-right: 1px lightgray solid;
        font-family: 'Londrina Solid', sans-serif;
        padding: 0px;
        text-align: left;
        .table-header {
          background: #F6F6FF;
          border-bottom: 1px lightgray solid;
          color: gray;
          padding: 8px;
          width: 100%;
          h5 {
            display:inline-block;
            margin-bottom: 0px;
          }
          span {
            color: gray;
            float: right;
            font-size: 1.2em;
            margin: 2px 0px;
          }
          
          img {
            bottom: 2px;
            display: inline-block;
            height: 20px;
            margin-right: 4px;
            position: relative;
          }
          .tip-content {
            font-size: 0.8rem;
            top: 40px;
            right: 0px;
            word-break: break-word;
            strong {
              font-weight: bold;
            }
          }           
        }
        .table-data {
          padding: 8px;
          .button-smaller {
            border: 3px solid rgba(0,0,0,0.001);
            -webkit-border-image: url(../../images/bg/border-diagonal-blue-7px.png) 7 fill round;
            -moz-border-image: url(../../images/bg/border-diagonal-blue-7px.png) 7 fill round;
            -o-border-image: url(../../images/bg/border-diagonal-blue-7px.png) 7 fill round;
            border-image: url(../../images/bg/border-diagonal-blue-7px.png) 7 fill round;
          }
          .dropdown {
            button {
            visibility: hidden;
            }
          }

          .dropdown:hover {
            transform: translateY(0px);
          }

          .dropdown button {
            position: absolute;
            left: -3px;
            top: 20px;
            min-width: 100px;
          }
          .dropdown:hover button {
            visibility: visible;
          }
          
        }
      }
  }

  .navbar-inventory-nav {
      background-color: #F6F6FF;
      height: 32px;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: 3px solid lightgray;
      span {
        flex-grow: 1;
      }
      .tip-content {
        font-family: 'Open Sans', sans-serif;
        font-size: 0.8rem;
      }
  }

  .navbar-inventory-nav a {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      list-style-type: none;
      height: 100%;
      min-width: 140px;
      color: gray;
      border-right: 1px solid lightgray;
  }

  .navbar-inventory-nav a.active {
      background-color:orange;
      color: white;
      text-shadow: 0px 0px;
  }

  .navbar-inventory-nav .arkane {
    align-self: end;
  }

  .navbar-inventory-nav span {
      font-family: 'Londrina Solid', sans-serif;
      font-size: 1.1rem;
      text-decoration: none;
  }
}
