@charset "UTF-8";
.Public {
  /* original css */ }
  .Public .min {
    padding-top: 5px;
    padding-left: 10px;
    color: #cccccc; }
  .Public .max {
    padding-top: 5px;
    padding-right: 10px;
    color: #cccccc; }
  .Public #helper {
    padding-top: 40px;
    color: #46b7d5;
    font-style: italic; }
  .Public output {
    display: inline-block;
    font-size: 2em; }
  .Public .rangeslider,
  .Public .rangeslider__fill {
    display: block;
    -moz-box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.3);
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px; }
  .Public .rangeslider {
    background: #e6e6e6;
    position: relative; }
  .Public .rangeslider--horizontal {
    height: 10px;
    width: 300px; }
  .Public .rangeslider--vertical {
    width: 20px;
    min-height: 150px;
    max-height: 100%; }
  .Public .rangeslider--disabled {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
    opacity: 0.4; }
  .Public .rangeslider__fill {
    background: gray;
    position: absolute; }
  .Public .rangeslider--horizontal .rangeslider__fill {
    top: 0;
    height: 100%; }
  .Public .rangeslider--vertical .rangeslider__fill {
    bottom: 0;
    width: 100%; }
  .Public .rangeslider__handle {
    background: white;
    color: mediumseagreen;
    font-weight: bold;
    font-size: 18px;
    border: 1px solid #ccc;
    cursor: pointer;
    display: inline-block;
    width: 80px;
    height: 40px;
    line-height: 38px;
    text-align: center;
    position: absolute;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwLjAiLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4xIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g");
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(100%, rgba(0, 0, 0, 0.1)));
    background-image: -moz-linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.1));
    background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.1));
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.1));
    -moz-box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    -moz-border-radius: 22px;
    -webkit-border-radius: 22px;
    border-radius: 22px; }
  .Public .rangeslider__handle:after {
    font-family: FontAwesome;
    content: "";
    color: #cccccc; }
  .Public .rangeslider__handle:before {
    font-family: FontAwesome;
    content: "";
    color: #cccccc; }
  .Public .rangeslider__handle:active {
    background-img: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwLjEiLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4xMiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==");
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, rgba(0, 0, 0, 0.1)), color-stop(100%, rgba(0, 0, 0, 0.12)));
    background-image: -moz-linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.12));
    background-image: -webkit-linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.12));
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.12)); }
  .Public .rangeslider--horizontal .rangeslider__handle {
    top: -15px;
    touch-action: pan-y;
    -ms-touch-action: pan-y; }
  .Public .rangeslider--vertical .rangeslider__handle {
    left: -10px;
    touch-action: pan-x;
    -ms-touch-action: pan-x; }
  .Public input[type="range"]:focus + .rangeslider .rangeslider__handle {
    -moz-box-shadow: 0 0 8px rgba(255, 0, 255, 0.9);
    -webkit-box-shadow: 0 0 8px rgba(255, 0, 255, 0.9);
    box-shadow: 0 0 8px rgba(255, 0, 255, 0.9); }
