.GameInfo {
	.section-header.header-main.game-info {
		height: 100vh;
		background-image: url("../../images/game-info/game-info-header.jpg");
	}
	.section-race {
	    color: white;
	}

	.section-race p {
	    color: rgba(255,255,255,0.8);
	}


	div.col-md.pad-20 {
	    padding: 0px 10px 20px 10px;
	}

	div.col-md-6.pad-20 {
	    padding: 0px 10px 20px 10px;
	}
}
