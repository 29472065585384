.bg {
  background: #F6F6FF;
  padding: 1rem 2rem;
  border-bottom: 3px solid lightgray;
  word-break: break-all;
  overflow-wrap: break-word;

  .balance {
    width: 17rem;
  }
}