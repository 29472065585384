.Public {
.section-header.ck {
    height: initial !important;
    padding: 40px;
    background: url(../../images/ckitties/bg-header-ck@2x.jpg) center no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    .header-content {
       padding: 0px 30px;
        }
    }

    .bg-ck {
        background-color: #f4f5fd;
        .h-diamond h2 {
        color: #f9fb35;
        }
        .h-diamond:before{
          border-bottom: 30px solid #966aba;
          border-left: 30px solid #f4f5fd;
          border-right: 30px solid #f4f5fd;
        }
        .h-diamond:after{
          border-top: 30px solid #966aba;
          border-left: 30px solid #f4f5fd;
          border-right: 30px solid #f4f5fd;
        }
    }
.title.s1 {
    h4 {
    max-width: 450px;
    margin: 0 auto;
    }
}

.violator {
    background: #e6e7fb;
    color: rgba(0,0,0,0.7);
    min-height: 60px;
    padding: 15px;
    vertical-align: middle;
    span {
        display: inline-block;
        margin:5px 0px;
    }
    img {
        max-height: 30px;
        display: inline-block;
    }
}
.horizontal-slider {
    width: 100%;
    .promo-thumb {
        background-color: #FF8824;
        border-radius: 10px;
        color: white;
        font-family: 'Londrina Solid', sans-serif;
        height: 40px;
        line-height: 40px;
        text-align: center;
        width: 40px;
    }
    .promo-track {
        border-radius: 10px;
        height: 20px;
        top: 10px;
    }
    .promo-track-0 {
        background-color: #ffc107;
    }
    .promo-track-1 {
        background-color: lightgray;
    }
}

    .reactslider-wrapper-ck {
    height: 60px;
    }

@media (min-width: 576px) {
    .section-header .container {
    padding: 20px 15px 20px 15px;
    }
}

.s1.section-header {
    height: initial;
    .container {
        h1 {
            padding-top: 32px;
        }
    }   
}

.crate-list {
    padding: 20px 0px;
    h6 {
        font-size: 0.8rem;
    }
    img {
        max-width: 70px;
    }

    span {
        font-size: 0.6rem;
    }
}

.row {
    .col-md-5 {
    padding: 0px 40px;
    }
}

.section-header .container-fluid.referral-panel {
    padding: 10px;
}

.presale-headline {
    padding-top: 20%;
    font-size: 1.6rem;
}

.presale-headline-text {
    font-size: 0.8rem;
}

.main-crate-image {
    width: 100%;
    max-width: 300px;
    max-height: 300px;
}

.presale-header-cars {
    padding: 20px;
}

.presale-header-cars div {
    padding: 0px;
    margin: -5px;
}

.referral-panel {
    background: #121256;
    padding: 0px 12px;
    visibility: hidden;
}

.referral-text {
    font-size: 0.7rem!important;
    color: white;
}

.input-group {

}
.input-wrapper {
    max-height: 30px;
    margin-right: 10px;
    background: white;
    border-radius: 10px;
    color: gray;
    font-size: 0.9rem;
    display: inline-block;
    min-width: 130px;
    max-width: 130px;
    overflow: hidden;
    padding: 5px;
    line-height: 1.32rem;
}

/* referral-field {
    background: rgba(0,0,0,0);
    border: 0px solid white;
    font-size: 0.5rem!important;
    padding: 5px;

} */



.referral-share-button {
    background-color: orange;
    padding: 5px 15px;
    border: 0px solid white;
    color: white;
    font-size: 0.8rem;
    border-radius: 10px;
}


/* Presale Purchase */

.crates-presale-nav {
    padding: 0px;
}

.crates-presale-nav-item {
    border: none;
    background: rgba(0,0,0,0);
    max-width: 93px;
}

.crates-presale-nav-item h6 {
    font-size: 1rem;
    margin-bottom: 0.5rem;
}

.crates-presale-nav-item img {
    margin-bottom: 0.3rem;
    max-height: 44px;
}

.crates-presale-nav-item span {
    font-size: 0.7rem;
}

.presale-discount-countdown {
    color: white;
}

.store-crate-info ul {
  display: block;
  list-style-type: disc;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 20px;
}

.store-crate-info ul li {
    margin-bottom: 1rem;;
}

.form-control-range {
    max-width: 100%;
}

.crate-amt-submit.button-common {
    font-size: 1.2em!important;
}

.custom-select.currency {
    width: 170px;
}

body > header > div > h1 {
    font-size: 2rem;
}

body > header > div > p {
    font-size: 0.8rem;
}

.referral-panel {
    margin-top: -32px;
}

.referral-panel span {
    font-size: 0.7rem;
}

.car-brand-title {
    position: absolute;
    right: 20px;;
    bottom: 20px;
    font-size: 18px;
    color: white;
    padding: 10px;
    border: 5px solid white;
    border-radius: 10px;
}

.section-start {
    background-image: url(../../images/presale/bg.jpg);
    background-size: cover;
}

.card.metamask {
    border-radius: 0px;
    min-height: 350px;
    width: 250px;
    margin: 0px 5px 20px 5px;
}

.card h5 {
    font-size: 1.5rem;
    margin-bottom: 0.75rem;
}

.card p {
    font-size: 0.8rem;
    padding-left: 8px;
    padding-right: 8px;
}

#convertTo {
    font-size: 0.8rem;
    height: 100%;
}

// .btn.btn-success {
//     background-color: #31cb9e;
//     border-radius: 0px;
//     border: none;
// }

.btn.btn-success {
  background-color: #303955;
  padding: 0.5em 1.5em;
  border-radius: 0.5em;
  border: none;
}

.btn.btn-success:hover {
  background-color: #303955;
}

label.mb-4 {
    font-size: 2rem;
    font-family: 'Londrina Solid', sans-serif;
}

.crate-rateup {
    display: inline-block;
    color: orange;
}

.card-array.ck {
    .feature-a {
    background-image: url(../../images/ckitties/feature-a.jpg);
    background-size: cover; 
    background-position: center;
    }
    .feature-b {
    background-image: url(../../images/ckitties/feature-b.jpg);
    background-size: cover; 
    background-position: center;
    }
}
}