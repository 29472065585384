.FAQ {
  .section-header.faq{
      background: url("../../images/bg/bg-gray.jpg")bottom no-repeat;
      background-size:cover;
      height: 500px;
  }

  .section-founders {
      background: url("../../images/bg/bg-gray.jpg")no-repeat;
      background-size: contain;
      background-position: bottom;
  }

  h3 {
  }
  table {
    width: 100%;
    td {
    span {
      display: block;
      font-size: 1.3rem;
      padding-bottom: 0.4rem;
    }
    .year {
      padding-right: 5rem;
      width: 40%;
      text-align: end;
      vertical-align: top;
    } 
  }
  
  }
  .about-item {
      padding: 0px 15px 20px 15px;
      margin-bottom: 20px;
  }

  .about-item img {
      max-width: 180px;
      border-radius: 50%;
      margin-bottom: 20px;
  }

  .about-item h5 {
      margin-bottom: 0px;
  }
  .section-execteam p {
      line-height: 1.5em;
      color: rgba(255,255,255,0.8);
  }

  .fa-linkedin {
      font-size: 2rem;
      margin-top: 10px;
      color: black;
  }

  .section-execteam .fa-linkedin {
      color: white;
  }

  .team-photo {
      width: 100%;
      background: url("../../images/about-us/team-photo.jpg") center no-repeat;
      background-color: gray;
      background-size: cover;
  }

  .p-50 {
      padding-left: 50px;
      padding-right: 50px;
  }


  module h3 {
      font-family: 'Open Sans', sans-serif;
      font-size: 0.1rem!important;
  }

  .faq-content h3 {
      margin-bottom: 20px;
  }

  .faq-content p {
      font-size: 1.2rem;
      line-height: 2;
      margin-bottom: 50px;

  }



  .header-main-headline {
      margin-bottom: 1rem;
  }

  body > section.section-founders.container-fluid > div > h3 {
      font-family: 'Open Sans', sans-serif!important;
      font-size: 1.6rem!important;
      font-weight: 800;
      margin:1rem 0 0 0!important;
      text-decoration: underline;

  }

  body > section.section-founders.container-fluid > div > h3 > a {
      color: black;
  }

  /* body > section.section-founders.container-fluid > div > p {
     margin-bottom:4rem!important;
  } */
}
